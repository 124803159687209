import { Card, CardHeader, Grid } from "@mui/material";

export default function MeetingCard() {
    return (
        <Grid item sx={{ width: "100%" }}>
            <Card sx={{ backgroundColor: "#16E700" }}>
                <CardHeader
                    sx={{ textAlign: "center", textTransform: "uppercase", color: "#000", }}
                    title="Next Event: Tuesday 8/13 | Freshmen Orientation"
                />
                <CardHeader
                    sx={{ textAlign: "center", textTransform: "uppercase", borderTopRightRadius: "32px", borderTopLeftRadius: "32px", backgroundColor: "#101010" }}
                    title={<a style={{ color: "white" }} href="https://chscompclub.com/discord">Click to Join our Discord Server!</a>}
                />
            </Card>
        </Grid>
    )
}